import { gql } from 'urql'
import { TaskItemType } from '../types'

const QueryTasksList = gql<
  {
    tasksList: {
      collection: Array<TaskItemType>
      metadata: {
        totalCount: number
      }
    }
  },
  {
    page: number
    limit: number
    tab: string
    profileId: number
    applicantId: number
    filterBy: string
    dueDateExisting: boolean
    assigneesIds: number[]
  }
>`
  query (
    $page: Int
    $limit: Int
    $tab: String
    $profileId: Int
    $applicantId: Int
    $filterBy: String
    $dueDateExisting: Boolean
    $assigneesIds: [Int!]
  ) {
    tasksList(
      page: $page
      limit: $limit
      tab: $tab
      profileId: $profileId
      applicantId: $applicantId
      filterBy: $filterBy
      dueDateExisting: $dueDateExisting
      assigneesIds: $assigneesIds
    ) {
      collection {
        id
        title
        dueDate
        status
        comments {
          content
        }
        profile {
          id
          fullName
          avatarVariants
        }
        applicant {
          profile {
            id
            fullName
          }
        }
        applicantId
        assignees {
          id
          fullName
          avatarVariants
          defaultColour
          roles {
            id
            name
          }
        }
        company {
          id
          name
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTasksList
