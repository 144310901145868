import { gql } from 'urql'
import { IProfileListType } from '../../candidates/types'

const QueryRelateProfilesList = gql<
  {
    profilesList: {
      collection: IProfileListType[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query ($limit: Int, $page: Int, $search: String, $sorting: JSON) {
    profilesList(
      limit: $limit
      page: $page
      search: $search
      sorting: $sorting
    ) {
      collection {
        id
        fullName
        email
        avatarVariants
        defaultColour
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryRelateProfilesList
