import { TFunction } from 'i18next'
import { z } from 'zod'
import { removeHTMLTags } from '~/core/utilities/common'

const schemaTaskForm = (t: TFunction) => {
  return z.object({
    title: z
      .string()
      .trim()
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .max(500, {
        message: `${t('form:field_max_number_required', {
          number: 500
        })}`
      })
      .refine(async (content) => removeHTMLTags(content || '').length <= 500, {
        message: `${t('form:field_max_number_required', { number: 500 })}`
      }),
    assigneeIds: z.array(z.object({ value: z.string() })).min(1, {
      message: `${t('form:requiredField')}`
    }),
    dueDate: z.date().optional(),
    relatedIds: z.object({ value: z.string() }).nullable().optional()
  })
}

export default schemaTaskForm
