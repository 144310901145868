import { gql } from 'urql'
import { CompanyItemListingType } from '../types/company-detail'

const QueryCompaniesList = gql<
  {
    companiesList: {
      collection: CompanyItemListingType[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query (
    $page: Int
    $limit: Int
    $search: String
    $ownerIds: [Int!]
    $jobStatistic: String
    $country: String
    $state: String
    $companyStatusIds: [Int!]
    $fromDatetime: ISO8601DateTime
    $toDatetime: ISO8601DateTime
    $countryStateId: Int
    $contactId: Int
    $departmentIds: [Int!]
  ) {
    companiesList(
      page: $page
      limit: $limit
      search: $search
      ownerIds: $ownerIds
      jobStatistic: $jobStatistic
      country: $country
      state: $state
      companyStatusIds: $companyStatusIds
      fromDatetime: $fromDatetime
      toDatetime: $toDatetime
      countryStateId: $countryStateId
      contactId: $contactId
      departmentIds: $departmentIds
    ) {
      collection {
        id
        tenantId
        ownerId
        logoVariants
        name
        owner {
          fullName
          avatarVariants
          defaultColour
        }
        domain
        links
        companyLocations {
          country
          state
          city
          countryStateId
        }
        companyStatus {
          name
        }
        taxCode
        foundedYear
        industries {
          name
        }
        description
        createdAt
        statistic {
          openJobsCount
          archivedJobsCount
        }
        permittedFields
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryCompaniesList
