import { FC, useState } from 'react'
import { FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiValue, SingleValue } from 'react-select'
import {
  AGENCY_TENANT,
  TAB_CANDIDATES,
  TAB_COMPANIES
} from '~/core/constants/enum'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Button, ButtonProps } from '~/core/ui/Button'
import { ISelectOption } from '~/core/ui/Select'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { cn } from '~/core/ui/utils'
import { DESC_SORTING } from '~/lib/features/candidates/utilities/enum'
import useTasksManagement from '~/lib/features/tasks/hooks/use-tasks-management'
import { TaskFormType } from '~/lib/features/tasks/types'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import useBoundStore from '~/lib/store'

const TaskRelatedSelect: FC<{
  value?: ISelectOption
  onChange: (
    value?: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null
  ) => void
  formState: FormState<TaskFormType>
  buttonSize?: ButtonProps['size']
  classNameDropdown?: string
}> = ({ value, onChange, formState, buttonSize = 'sm', classNameDropdown }) => {
  const { t } = useTranslation()
  const { isCompanyKind: isAgency } = useDetectCompanyWithKind({
    kind: AGENCY_TENANT
  })
  const { currentTabRelated: currentTab, setCurrentTabRelated: setCurrentTab } =
    useBoundStore()

  const [openDropdown, setOpenDropdown] = useState(false)
  const { promiseProfileOptions, promiseCompanyListOption } =
    useTasksManagement({ isCalendar: false })
  return (
    <>
      <div className="relative z-[9999]">
        <Button
          className="line-clamp-1 whitespace-nowrap"
          iconMenus="ChevronDown"
          size={buttonSize}
          icon="trailing"
          type="secondary"
          label={
            value?.value && value.supportingObj
              ? value.supportingObj?.name?.length > 10
                ? `${value.supportingObj?.name.slice(0, 10)}...`
                : value.supportingObj?.name
              : `${t('label:placeholder:relatedTo')}`
          }
          onClick={() => {
            setOpenDropdown(!openDropdown)
          }}
        />
        {openDropdown ? (
          <div
            className={cn(
              'absolute right-0 z-50 mt-1 min-w-[320px] rounded-t-md bg-white shadow-[0px_0px_0px_1px_rgba(0,0,0,0.05),0px_4px_6px_-2px_rgba(0,0,0,0.05),0px_10px_15px_-3px_rgba(0,0,0,0.10)]',
              isAgency
                ? 'top-[calc(-100%_-_320px)]'
                : 'top-[calc(-100%_-_285px)]',
              classNameDropdown
            )}>
            {isAgency ? (
              <>
                <div className="p-2">
                  <Tabs
                    value={currentTab || TAB_CANDIDATES}
                    onValueChange={(newValue) => {
                      setCurrentTab(newValue)
                      onChange(null)
                    }}>
                    <TabsList isFullWidth size="xs" typeTab="switch-tab">
                      <TabsTrigger
                        isFullWidth
                        className="flex"
                        size="xs"
                        typeTab="switch-tab"
                        value={TAB_CANDIDATES}>
                        <TabsTriggerView
                          session={{
                            label: `${t('label:candidates')}`,
                            value: TAB_CANDIDATES
                          }}
                          size="xs"
                          typeTab="switch-tab"
                        />
                      </TabsTrigger>
                      <TabsTrigger
                        isFullWidth
                        gapSize="md"
                        size="xs"
                        typeTab="switch-tab"
                        value={TAB_COMPANIES}>
                        <TabsTriggerView
                          session={{
                            label: `${t('label:companies')}`,
                            value: TAB_COMPANIES
                          }}
                          size="xs"
                          typeTab="switch-tab"
                        />
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
              </>
            ) : undefined}
            <AsyncSingleSearchWithSelect
              promiseOptions={(params) => {
                return isAgency && currentTab === TAB_COMPANIES
                  ? promiseCompanyListOption(params)
                  : promiseProfileOptions({
                      ...params,
                      sorting: { id: DESC_SORTING }
                    })
              }}
              size="md"
              isClearable={true}
              onChange={(newValue) => {
                onChange(newValue)
                setOpenDropdown(false)
              }}
              configSelectOption={{
                supportingText: ['name', 'description']
              }}
              variant="ghost"
              placeholder={`${t('label:placeholder:search')}`}
              value={value}
              destructive={formState.errors && !!formState.errors.assigneeIds}
              menuIsOpen
              classNameOverride={{
                menu: 'mt-0 rounded-t-none',
                control: 'rounded-none',
                menuList: 'h-[250px]'
              }}
              loadAsyncWhenRender
              key={currentTab}
            />
          </div>
        ) : null}
      </div>
      {openDropdown ? (
        <div
          onClick={(e) => setOpenDropdown(false)}
          className="absolute bottom-0 left-0 right-0 top-0 z-[49] !mx-0"
        />
      ) : null}
    </>
  )
}

export default TaskRelatedSelect
